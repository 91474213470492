<template>
  <SetsRounds :show-empty="false" />
</template>
<script>
import SetsRounds from './lottoset/components/SetsRounds'

export default {
  name: 'LottoSets',
  components: {
    SetsRounds
  }
}
</script>
